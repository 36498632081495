export function initialize(gaTrackingId, path, title) {
  // Create a script element for the external GTM script
  const gtmScript = document.createElement("script");
  gtmScript.type = "text/plain";
  gtmScript.setAttribute("data-cookie-consent", "tracking");
  gtmScript.async = true;
  gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=" + gaTrackingId;

  // Append the external script to the document head
  document.head.appendChild(gtmScript);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag("js", new Date());
  gtag("config", gaTrackingId);
  gtag("event", "page_view", {
    send_to: gaTrackingId,
    page_title: title,
    page_path: path
  });
}
