import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styles from "./Header.scss";
import { ReactComponent as Hamburger } from "../icons/Hamburger.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import configs from "../../utils/configs";
import { AvatarMenu } from "./AvatarMenu";
import { useLogo } from "../styles/theme";

export function MobileNav({
  isHmc,
  isSignedIn,
  showDocsLink,
  docsUrl,
  showSourceLink,
  showCommunityLink,
  communityUrl,
  isAdmin
}) {
  const [navOpen, setNav] = useState(false);
  const toggleNav = () => {
    setNav(!navOpen);
  };

  const logo = useLogo();
  const cloud = isHmc ? null : "cloud";

  const hero = document.getElementById("hero-cont");
  if (hero && window.innerWidth < 1000) {
    hero.style.top = navOpen ? "0px" : "50px";
  } else if (hero && window.innerWidth > 1000) {
    hero.style.top = "0px";
  }

  return (
    <>
      <div>
        <header className={styles.navContainer}>
          <nav>
            <ul>
              <li>
                <a href="/" className={styles.homeLink}>
                  {/*
                This forceConfigurableLogo prop is a bit of a hack, since we want the home page on HMC to use our 
                configured logo, which is left-aligned, as opposed to the logo that we typically used for HMC, 
                which is center-aligned.
                */}
                  <img style={{ height: "30px" }} alt={configs.translation("app-name")} src={logo} />
                </a>
              </li>
            </ul>
          </nav>
          <div style={{ display: "flex" }}>
            {isSignedIn && (
              <div style={{ marginTop: "-4px" }}>
                <AvatarMenu />
              </div>
            )}
            <div className={styles.mobileNavWrapper}>
              <Hamburger style={{ width: "60px" }} onClick={toggleNav} />
            </div>
          </div>
        </header>
        <div className={styles.mobileNavWrapper}>
          <header id="mobile-menu" className={`${navOpen ? `is-active ${cloud}` : "hide"}`}>
            <nav role="navigation">
              <ul>
                {configs.NFT_MARKETPLACE_URL && (
                  <li>
                    <a href={configs.NFT_MARKETPLACE_URL}>
                      <FormattedMessage id="header.Marketplace" defaultMessage="Marketplace" />
                    </a>
                  </li>
                )}
                {isHmc && (
                  <li>
                    <a href="/spoke">
                      <FormattedMessage id="header.spoke" defaultMessage="Spoke" />
                    </a>
                  </li>
                )}
                {showDocsLink && (
                  <li>
                    <a href={docsUrl}>
                      <FormattedMessage id="header.docs" defaultMessage="Guides" />
                    </a>
                  </li>
                )}
                {showSourceLink && (
                  <li>
                    <a href="https://github.com/mozilla/hubs">
                      <FormattedMessage id="header.source" defaultMessage="Developers" />
                    </a>
                  </li>
                )}
                {showCommunityLink && (
                  <li>
                    <a href={communityUrl}>
                      <FormattedMessage id="header.community" defaultMessage="Community" />
                    </a>
                  </li>
                )}
                {isHmc && (
                  <li>
                    <a href="/cloud">
                      <FormattedMessage
                        id="header.cloud"
                        defaultMessage="{appName}"
                        values={{ appName: configs.APP_NAME }}
                      />
                    </a>
                  </li>
                )}
                {isHmc && (
                  <li>
                    <a href="/labs">
                      <FormattedMessage id="header.labs" defaultMessage="Labs" />
                    </a>
                  </li>
                )}
                {isAdmin && (
                  <li>
                    <a style={{ marginLeft: 0 }} href="/admin" rel="noreferrer noopener">
                      <i>
                        <FontAwesomeIcon icon={faCog} />
                      </i>
                      &nbsp;
                      <FormattedMessage id="header.admin" defaultMessage="Admin" />
                    </a>
                  </li>
                )}
                <li>
                  <a href="/link">
                    <FormattedMessage id="home-page.have-code" defaultMessage="Have a metaverse code?" />
                  </a>
                </li>

                {!isSignedIn && (
                  <li>
                    <a href="/signin">
                      <FormattedMessage id="header.signin" defaultMessage="Sign in/Sign up" />
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
}

MobileNav.propTypes = {
  showDocsLink: PropTypes.bool,
  docsUrl: PropTypes.string,
  showSourceLink: PropTypes.bool,
  showCommunityLink: PropTypes.bool,
  communityUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  isHmc: PropTypes.bool
};
