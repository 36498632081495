import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styles from "./ContactMeArea.scss";
import { Container } from "../layout/Container";
import { Column } from "../layout/Column";

export function ContactMeArea({ email }) {
  return (
    <Container>
      <Column center grow className={styles.contactMeArea}>
        <h2 className={styles.contactMeAreaTitle}>
          <FormattedMessage id="contact-me-area.title" defaultMessage="Contact me" />
        </h2>
        <a href={`mailto:${email}`} className={styles.contactMeAreaEmail}>
          {email}
        </a>
      </Column>
    </Container>
  );
}

ContactMeArea.propTypes = {
  email: PropTypes.string
};
