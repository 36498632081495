import configs from "./configs";
import DashboardChannel from "./dashboard-channel";
import Cookies from "js-cookie";

const qs = new URLSearchParams(location.search);

export function getCookieToken(cookieFallback) {
  // Get from the local storage "auth0Token"
  let token = localStorage.getItem("auth0Token");

  if (!token && cookieFallback) {
    token = Cookies.get(configs["JWT_COOKIE"]);
  }

  return token;
}

export function setCookieToken(token) {
  // Set the local storage "auth0Token"
  localStorage.setItem("auth0Token", token);
}

export default async function getAuthUser(store, cookieFallback = false, refetch = false) {
  if (store?.state.authUser?.name && !refetch) {
    if (window.gtag) {
      window.gtag("set", { user_id: store.state.authUser.id });
    }

    return store.state.authUser;
  }

  const url = configs["CUSTOM_API_URL"] + "/auth0/user";
  const token = getCookieToken(cookieFallback);
  const code = qs.get("code");

  // if the code exists, remove it from the url
  if (code) {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname + window.location.search.replace(/&?code=[^&]+/, "")
    );
  }

  if (!token && !code) {
    return null;
  }

  if (token) {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json"
        }
      }).catch(() => {
        return null;
      });

      if (!response.ok) {
        return null;
      }

      const data = await response.json();

      if (!cookieFallback) {
        store.update({ authUser: data });
        store.update({ profile: { ...store.state.profile, user_color: data.user_color } });
      }

      if (window.gtag) {
        window.gtag("set", { user_id: store.state.authUser.id });
      }

      return data;
    } catch (err) {
      return null;
    }
  }

  if (code) {
    try {
      const email = store.state.credentials?.email;
      const dashboardChannel = new DashboardChannel(store);

      console.log("Code found, attempting to login with code", code, email);

      if (email) {
        const data = await dashboardChannel.loginWithCode(code, email).catch(() => {
          return null;
        });

        console.log("Login with code response", data);

        if (data) {
          store.update({ authUser: data });
          store.update({ profile: { ...store.state.profile, user_color: data.user_color } });
        }

        return data;
      }
    } catch (err) {
      console.error("Error logging in with code", err);
      return null;
    }
  }
}
