import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";
import styles from "./HighlightedRoom.scss";
import { Button } from "../input/Button";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { FormattedMessage } from "react-intl";

export function HighlightedRoom({ room, className }) {
  const breakpoint = useCssBreakpoints();

  return (
    <div className={classNames(styles.highlightedRoom, className)}>
      <div className={styles.roomContent}>
        {room.artist && <p className={styles.artist}>{room.artist}</p>}
        <p className={styles.title}>{room.name}</p>
        <p className={styles.description}>{room.description}</p>
        <Button
          className={styles.button}
          thick={breakpoint === "sm" || breakpoint === "md"}
          xl={breakpoint !== "sm" && breakpoint !== "md"}
          preset="landing"
          onClick={e => {
            e.preventDefault();
            window.location.href = room.url;
          }}
        >
          <FormattedMessage id="create-room-button" defaultMessage="Enter Metaverse" />
        </Button>
      </div>

      {room.video ? (
        <video className={styles.backgroundImage} poster={room.images.preview.url} autoPlay muted loop playsInline>
          <source src={room.video} />
        </video>
      ) : (
        <img className={classNames(styles.backgroundImage)} src={room.images.preview.url} alt={room.name} />
      )}
    </div>
  );
}

HighlightedRoom.propTypes = {
  room: PropTypes.object,
  className: PropTypes.string
};
