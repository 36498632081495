import React, { useRef, useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Logout } from "../icons/Logout.svg";
import PropTypes from "prop-types";
import { AuthContext } from "../auth/AuthContext";
import styles from "./AvatarMenu.scss";
import Store from "../../storage/store";

export function AvatarMenu() {
  const auth = useContext(AuthContext);
  const [closed, setOpen] = useState(true);

  const ref = useRef(null);

  const store = new Store();
  const name = store.state.profile.displayName ?? store.state.credentials.email.split("@")[0] ?? "User";
  const firstLetter = name.charAt(0).toUpperCase();
  const email = store.state.credentials.email;

  const mobileMenu = document.getElementById("mobile-menu");
  if (mobileMenu) {
    mobileMenu.style.zIndex = closed ? "99" : "0";
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current) {
        const id = event.target.id ? event.target.id : "";
        const parentId = id ? "" : event.target.parentElement.id;

        if (id !== "dropdown-menu-click" && parentId !== "dropdown-menu-click") {
          setOpen(true);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div id="dropdown-menu" className={styles.dropdown}>
      <span onClick={() => setOpen(!closed)} className={styles.avatar}>
        {firstLetter}
      </span>
      <div
        id="dropdown-menu-click"
        ref={ref}
        className={styles.dropdownContent}
        style={{ right: closed ? "0" : "-83px", opacity: closed ? "0" : "1" }}
      >
        <li className={styles.sectionTitle}>
          <FormattedMessage id="dropdown.name" defaultMessage="{name}" values={{ name }} />
        </li>
        <li className={styles.linkItemEmail}>
          <FormattedMessage id="dropdown.email" defaultMessage="{email}" values={{ email }} />
        </li>
        <li onClick={auth.signOut} style={{ marginTop: "30px" }}>
          <a className={styles.linkSubItem} style={{ display: "flex", alignItems: "center" }}>
            <Logout className={styles.linkIcon} />
            <span>
              <FormattedMessage id="dropdown.logout" defaultMessage="Logout" />
            </span>
          </a>
        </li>
      </div>
    </div>
  );
}

AvatarMenu.propTypes = {
  name: PropTypes.string
};
