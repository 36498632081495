import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { SubscribeAreaForm } from "./SubscribeAreaForm";

export function MailchimpFormContainer() {
  const postUrl = `https://foundation.us8.list-manage.com/subscribe/post?u=c33ce1feec96d12f55c022c41&amp;id=abc402c12c&amp;f_id=00fe65e0f0`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <SubscribeAreaForm status={status} message={message} onValidated={formData => subscribe(formData)} />
        )}
      />
    </div>
  );
}

export default MailchimpFormContainer;
