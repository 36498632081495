/* eslint-disable @calm/react-intl/missing-formatted-message */
import React, { useState } from "react";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { Button } from "../../input/Button";
import { TextInputField } from "../../input/TextInputField";
import styles from "./SubscribeAreaForm.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

export function SubscribeAreaForm({ status = null, message, onValidated }) {
  const breakpoint = useCssBreakpoints();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    email &&
      firstName &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        FNAME: firstName
      });
  };

  return (
    <div id="mc_embed_signup">
      {status === "success" && (
        <div className={classNames(styles.title, styles.formSuccess)} dangerouslySetInnerHTML={{ __html: message }} />
      )}

      {status !== "success" && (
        <form
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className={styles.form}
          onSubmit={e => handleSubmit(e)}
        >
          <div className={styles.formContent}>
            <h2 className={styles.title}>
              Subscribe with your email to hear about Open Calls, Exhibitions and Loop News
            </h2>
            <div className={styles.inputGroup}>
              <div className="mc-field-group">
                <TextInputField
                  name="FNAME"
                  type="text"
                  required
                  autoComplete="off"
                  placeholder="Name"
                  minLength={1}
                  maxLength={64}
                  fullWidth
                  id="mce-FNAME"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </div>
              <div className="mc-field-group">
                <TextInputField
                  name="EMAIL"
                  type="email"
                  required
                  autoComplete="off"
                  placeholder="Email Address"
                  minLength={1}
                  maxLength={64}
                  fullWidth
                  id="mce-EMAIL"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className={styles.submitArea}>
                <Button
                  className={styles.button}
                  thick={breakpoint === "sm" || breakpoint === "md"}
                  xl={breakpoint !== "sm" && breakpoint !== "md"}
                  preset="landing"
                  type="submit"
                  id="mc-embedded-subscribe"
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

SubscribeAreaForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func
};
