import configs from './configs';

export default function signOutUser() {
  const iframe = document.createElement("iframe");

  iframe.src = configs['CUSTOM_API_HOST'] + "/logout";
  iframe.style.display = "none";

  document.body.appendChild(iframe);

  // Wait for iframe to load before removing it.
  iframe.onload = () => {
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  };
}
