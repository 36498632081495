import React, { useState } from "react";
import styles from "./Faqs.scss";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import classNames from "classnames";

export function Faqs() {
  const FAQS = [
    {
      question: "Which metaverse is Loop built on? Is it affiliated with a corporation?",
      answer:
        "Loop is built on the metaverse Onland which was developed by The MUD Foundation, a 5o13c non-profit organization focused on Art & Technology. It is not affiliated with any corporation.",
      opened: false
    },
    {
      question: "Is Loop a profit-making business?",
      answer:
        "Loop is an artist project begun by Ariel Baron-Robbins. It does not make a profit and is funded by Ariel in collaboration with The MUD Foundation’s exhibition WASD.",
      opened: false
    },
    {
      question: "Why is there a $15 application fee?",
      answer:
        "The $15 application fee goes towards the flat $100 artist honorarium that every artist receives. If the amount exceeds the amount give to artists, the remaining money will go towards the monthly maintenance fee that is paid to Next Reality Digital who developed and maintains Loop Art Critique.",
      opened: false
    },
    {
      question: "How long is residency program at Loop?",
      answer:
        "The standard residency is 1 month. After the group is selected, they will vote if they want to meet 2 or 3 times were week. Live critiques are 2 hours long.",
      opened: false
    },
    {
      question: "Is LAC an educational program?",
      answer:
        "No, there is a moderator during critiques who gives their feedback along with the rest of the group.Critiques are a fundamental way for artists to receive feedback from a selected group of qualified peers.",
      opened: false
    },
    {
      question: "How long is the exhibition open?",
      answer: "The exhibitions in The Salon and The Salon des Refuses are open for 1 month after the residency.",
      opened: false
    },
    {
      question: "Who curates each residency’s exhibition?",
      answer: "The artist work as a group to curate the exhibition in The Salon. The Salon des Refuses is uncurated.",
      opened: false
    },
    {
      question: "Do I need to be “good” at technology?",
      answer:
        "A general understanding of moving around in virtual reality space or a video game is useful but not required. LAC is very simple and requires uploading artwork and positioning it. There is a 2 hour orientation and individual help can be given to artists in need.",
      opened: false
    },
    {
      question: "What kind of artwork does LAC accept?",
      answer:
        "All forms of digital art including but not limited to: still images like: jpegs, pngs, pdfs,, moving images like: gifs, mov, mp4s, audio like: wav and mp3 and VR performance art",
      opened: false
    },
    {
      question: "If LAC is an art project, then is my art considered part of Ariel Baron-Robbins artwork?",
      answer:
        "Yes and no. LAC is presented, until August 2023, inside of an exhibition at The MUD Foundation as part of its MUD 3.0 WASD exhibition. When visitors enter the exhibition, they see two screens, one that shows The Salon and one that shows The Salon des Refuses. It is labeled as Ariel Baron-Robbins project in the show. Individual artist’s work in the Salons is labled with each artist's name and a link to their artwork online. So they are considered participants inside of the work but their work is not part of the overall piece.",
      opened: false
    }
  ];

  const [faqs, setFaqs] = useState(FAQS);

  const toggleFaq = index => {
    const newFaqs = faqs.map((faq, i) => {
      if (i === index) {
        return { ...faq, opened: !faq.opened };
      }
      return faq;
    });

    setFaqs(newFaqs);
  };

  return (
    <div className={styles.faq}>
      <div className={styles.faqContainer}>
        <h2 className={styles.faqTitle}>
          <FormattedMessage id="home.faqs.title" defaultMessage="Frequently Asked Questions" />
        </h2>
        <div className={styles.faqContent}>
          {faqs.map((faq, i) => {
            return (
              <div className={styles.faqItem} key={i}>
                <div className={styles.faqQuestion} onClick={() => toggleFaq(i)}>
                  <h3>{faq.question}</h3>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className={classNames([styles.faqAnswer, faq.opened ? styles.opened : ""])}>
                  <p>{faq.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
