import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLogo, useMinilogo } from "../styles/theme";
// import propTypes from "prop-types";
import PropTypes from "prop-types";
import { AuthContext } from "../auth/AuthContext";
import configs from "../../utils/configs";
import { FormattedMessage } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardChannel from "../../utils/dashboard-channel";

export function ExternalNavBar({ appName }) {
  const logo = useLogo();
  const miniLogo = useMinilogo();
  const darkLogo = configs.image("logo_dark");
  const { store } = window.APP;
  const auth = useContext(AuthContext);
  const [authUser, setAuthUser] = useState(null);
  const [isAdmin, setAdmin] = useState(false);
  const [{ items, menuItems }, setMenu] = useState({
    items: [],
    menuItems: [
      {
        id: "Dashboard",
        name: "Dashboard",
        href: configs.CUSTOM_API_HOST + "/dashboard"
      }
    ]
  });
  const navBarRef = useRef(null);
  const loginUrl = "#";
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleItemClick = useCallback(
    e => {
      if (e.detail.id === "logout") {
        auth.signOut(true, isAuthenticated);
        setAuthUser(null);
      }
    },
    [auth, isAuthenticated]
  );

  const showMyMedia = () => {
    const myMediaModal = document.querySelector("my-media");

    if (myMediaModal) {
      myMediaModal.init();
      myMediaModal.style.display = "block";

      const mediaSelectedListener = e => {
        const entry = e.detail;

        this.props.scene.emit("action_selected_media_result_entry", { entry, selectAction: "spawn" });
        myMediaModal.close();
      };

      // Create an event listener that run once and then removes itself
      const closeMyMedialistener = () => {
        myMediaModal.style.display = "none";
        myMediaModal.removeEventListener("close", closeMyMedialistener);
        myMediaModal.removeEventListener("media-selected", mediaSelectedListener);
      };

      myMediaModal.addEventListener("close", closeMyMedialistener);
      myMediaModal.addEventListener("media-selected", mediaSelectedListener);
    }
  };

  useEffect(() => {
    const handleSignInClick = e => {
      e.preventDefault();
      loginWithRedirect({
        authorizationParams: {
          audience: configs.AUTH0_AUDIENCE
        }
      });
    };

    const handleAuth = async () => {
      let signInButton = document.querySelector("nav-bar")?.shadowRoot?.querySelector(".NavBar__sign-in");

      if (!signInButton) {
        await new Promise(resolve => setTimeout(resolve, 3000));
        signInButton = document.querySelector("nav-bar")?.shadowRoot?.querySelector(".NavBar__sign-in");
      }

      if (signInButton) {
        signInButton.addEventListener("click", handleSignInClick);
        return () => {
          signInButton.removeEventListener("click", handleSignInClick);
        };
      }
    };

    handleAuth();
  }, [loginWithRedirect]);

  useEffect(() => {
    const navBar = navBarRef.current;

    navBar.addEventListener("item-clicked", handleItemClick);

    return () => {
      navBar.removeEventListener("item-clicked", handleItemClick);
    };
  }, [handleItemClick]);

  useEffect(() => {
    const externalUser = store.state.authUser;
    const credentials = store.state.credentials;

    if (credentials?.email && !authUser) {
      setAuthUser({
        name: store.state.profile?.displayName || credentials.email.split("@")[0],
        email: credentials.email,
        user_color: store.state.profile?.user_color || "var(--primary-color)"
      });
    }

    if (authUser && authUser.user_color !== externalUser?.user_color) {
      setAuthUser({
        ...authUser,
        user_color: externalUser?.user_color
      });
    }

    if (authUser && !externalUser?.email && !credentials?.email) {
      setAuthUser(null);
    }
  }, [authUser, store.state.authUser, store.state.profile, store.state.credentials]);

  // Set auth user id = external user id
  useEffect(() => {
    if (authUser && authUser.id !== store.state.authUser?.id) {
      setAuthUser({
        ...authUser,
        id: store.state.authUser?.id,
        name: store.state.profile?.displayName || store.state.authUser.name || authUser.email.split("@")[0]
      });
    }
  }, [authUser, store.state.authUser, store.state.profile?.displayName]);

  useEffect(() => {
    if (isAdmin !== auth.isAdmin) {
      setAdmin(auth.isAdmin);
    }
  }, [auth.isAdmin, isAdmin]);

  useEffect(() => {
    const newMenuItems = [
      {
        id: "Dashboard",
        name: "Dashboard",
        href: configs.CUSTOM_API_HOST + "/dashboard"
      }
    ];

    if (configs["BLOG_URL"]) {
      newMenuItems.push({
        id: "blog",
        name: "Blog",
        href: configs["BLOG_URL"]
      });
    }

    if (isAdmin) {
      newMenuItems.unshift({
        id: "admin",
        name: "Admin",
        href: "/admin"
      });
    }

    const dashboardChannel = new DashboardChannel(window.APP.store);

    if (dashboardChannel.can("metaverse-editor")) {
      newMenuItems.push({
        id: "editor",
        name: "Scene Editor",
        href: "/spoke"
      });
    }

    setMenu({ items, menuItems: newMenuItems });

    if (authUser?.id && !items.some(item => item.id === "profile-settings")) {
      setMenu(prevState => ({
        ...prevState,
        items: [
          {
            id: "profile-settings",
            name: "Profile Settings",
            href: configs["CUSTOM_API_HOST"] + "/profile?redirect=" + window.location.href
          },
          {
            id: "logout",
            name: "Logout",
            href: "#"
          }
        ]
      }));
    }
  }, [isAdmin, authUser, items]);

  return (
    <nav-bar
      ref={navBarRef}
      app-name={appName}
      background="var(--navbar-background)"
      color="var(--navbar-text-color)"
      logo-small={miniLogo}
      logo-large={logo}
      logo-small-dark={configs.APP_NAME.startsWith("MUD") ? darkLogo : miniLogo}
      logo-large-dark={configs.APP_NAME.startsWith("MUD") ? darkLogo : miniLogo}
      sign-in-background="var(--primary-color)"
      sign-in-color="var(--text5-color)"
      sign-in-background-hover="var(--primary-color-hover)"
      sign-in-color-hover="var(--text5-color)"
      sign-in-border="2px solid var(--primary-color)"
      sign-in-border-hover="2px solid var(--primary-color-hover)"
      sign-in-url={loginUrl}
      user={authUser ? JSON.stringify(authUser) : null}
      items={JSON.stringify(items)}
      menu-items={JSON.stringify(menuItems)}
      border-radius="var(--border-radius-regular)"
      border-radius-small="var(--border-radius-small)"
      logo-max-height="40px"
      items-hover-color="var(--navbar-items-hover-color)"
    ></nav-bar>
  );
}

ExternalNavBar.propTypes = {
  appName: PropTypes.string
};
