import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Button } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

export function CloseButton({ ...rest }) {
  const intl = useIntl();

  return (
    <Button
      appearance="subtle"
      aria-label={intl.formatMessage({
        id: "room-settings-sidebar.close-button",
        defaultMessage: "Close"
      })}
      icon={<Dismiss24Regular />}
      {...rest}
    />
  );
}

CloseButton.propTypes = {
  className: PropTypes.string,
  lg: PropTypes.bool
};
