function getValueFromStringKey(key, str) {
  if (!str) return null;

  const re = new RegExp("\\[" + key + ":.*?\\]");
  const match = str.match(re);

  return match && match[0];
}

export function cleanDescription(description) {
  if (!description) return "";

  const videoKey = getValueFromStringKey("video", description);
  const positionKey = getValueFromStringKey("position", description);
  const artistKey = getValueFromStringKey("artist", description);
  const buttonKey = getValueFromStringKey("button", description);

  let newDescription = description.replace("#highlight", "");

  if (newDescription) {
    newDescription = newDescription.replace(videoKey, "");
    newDescription = newDescription.replace(positionKey, "");
    newDescription = newDescription.replace(artistKey, "");
    newDescription = newDescription.replace(buttonKey, "");
  }

  return newDescription;
}

export function getVideoUrl(description = "") {
  const videoKey = getValueFromStringKey("video", description);

  return videoKey && videoKey.replace("[video:", "").replace("]", "");
}

export function getArtistName(description = "") {
  const artistKey = getValueFromStringKey("artist", description);

  return artistKey && artistKey.replace("[artist:", "").replace("]", "");
}

export function getPosition(description) {
  if (!description) return null;

  const positionKey = getValueFromStringKey("position", description);

  return positionKey && positionKey.replace("[position:", "").replace("]", "");
}
