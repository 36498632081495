import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import styles from "./Header.scss";
import { Container } from "./Container";
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "../home/SignInButton";
import { AppLogo } from "../misc/AppLogo";
import { HaveARoomCodeButton } from "../home/HaveARoomCodeButton";
import { AvatarMenu } from "./AvatarMenu";
import configs from "../../utils/configs";

export function Header({
  showCloud,
  enableSpoke,
  editorName,
  showDocsLink,
  docsUrl,
  showSourceLink,
  showCommunityLink,
  communityUrl,
  isAdmin,
  isSignedIn,
  isHmc
}) {
  return (
    <header>
      <Container as="div" className={styles.containerNavbar}>
        <nav>
          <ul>
            <li>
              <a href="/" className={styles.homeLink}>
                <AppLogo />
              </a>
            </li>
          </ul>
        </nav>
        <div className={styles.signIn}>
          {/* new buttons */}
          <div className={styles.item}>
            {configs.NFT_MARKETPLACE_URL && (
              <li>
                <a href={configs.NFT_MARKETPLACE_URL}>
                  <FormattedMessage id="header.Marketplace" defaultMessage="Marketplace" />
                </a>
              </li>
            )}
            {isAdmin && (
              <li>
                <a href="/admin" rel="noreferrer noopener">
                  <i>
                    <FontAwesomeIcon icon={faCog} />
                  </i>
                  &nbsp;
                  <FormattedMessage id="header.admin" defaultMessage="Admin" />
                </a>
              </li>
            )}
            {enableSpoke && (
              <li>
                <a href="/spoke">
                  {isHmc ? <FormattedMessage id="header.spoke" defaultMessage="Spoke" /> : editorName}
                </a>
              </li>
            )}
            {showDocsLink && (
              <li>
                <a href={docsUrl}>
                  <FormattedMessage id="header.docs" defaultMessage="Guides" />
                </a>
              </li>
            )}
            {showSourceLink && (
              <li>
                <a href="https://github.com/mozilla/hubs">
                  <FormattedMessage id="header.source" defaultMessage="Developers" />
                </a>
              </li>
            )}
            {showCommunityLink && (
              <li>
                <a href={communityUrl}>
                  <FormattedMessage id="header.community" defaultMessage="Community" />
                </a>
              </li>
            )}
            {showCloud && (
              <li>
                <a href="/cloud">
                  <FormattedMessage
                    id="header.cloud"
                    defaultMessage="{appName}"
                    values={{ appName: configs.APP_NAME }}
                  />
                </a>
              </li>
            )}
            {isHmc && (
              <li>
                <a href="/labs">
                  <FormattedMessage id="header.labs" defaultMessage="Labs" />
                </a>
              </li>
            )}
            {!isSignedIn && <SignInButton />}
            <div className={styles.HaveARoomCodeButton}>
              <HaveARoomCodeButton />
            </div>
          </div>
        </div>

        {isHmc ? <SocialBar mobile /> : null}

        {isSignedIn && (
          <div>
            <AvatarMenu />
          </div>
        )}
      </Container>
    </header>
  );
}

Header.propTypes = {
  showCloud: PropTypes.bool,
  enableSpoke: PropTypes.bool,
  editorName: PropTypes.string,
  showDocsLink: PropTypes.bool,
  docsUrl: PropTypes.string,
  showSourceLink: PropTypes.bool,
  showCommunityLink: PropTypes.bool,
  communityUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  onSignOut: PropTypes.func,
  isHmc: PropTypes.bool
};
