import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";
import styles from "./MediaTiles.scss";
import { ReactComponent as PeopleIcon } from "../icons/People.svg";
import { ReactComponent as StarIcon } from "../icons/Star.svg";
import { ReactComponent as AddIcon } from "../icons/Add.svg";
import { ReactComponent as PenIcon } from "../icons/Pen2.svg";
import { ReactComponent as TrashIcon } from "../icons/trashbin.svg";
import { ReactComponent as DuplicateIcon } from "../icons/Duplicate.svg";
import { ReactComponent as SearchIcon } from "../icons/Search.svg";
import { ReactComponent as HelpIcon } from "../icons/Help.svg";
import { ReactComponent as Border } from "../icons/border.svg";
import { ReactComponent as ExternalLinkIcon } from "../icons/ExternalLink.svg";
import { fetchReticulumAuthenticated } from "../../utils/phoenix-utils";
import {
  Button,
  Card,
  CardFooter,
  CardPreview,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner
} from "@fluentui/react-components";
import {
  MoreVertical20Filled,
  Delete20Filled,
  ArrowDownload20Filled,
  Rename20Filled,
  Search20Filled,
  Copy20Filled
} from "@fluentui/react-icons";

const PUBLISHER_FOR_ENTRY_TYPE = {
  sketchfab_model: "Sketchfab",
  twitch_stream: "Twitch"
};

function useThumbnailSize(isImage, isAvatar, imageAspect) {
  return useMemo(() => {
    let imageHeight = 220;
    if (isAvatar) imageHeight = Math.floor(imageHeight * 1.5);

    // Aspect ratio can vary per image if its an image result. Avatars are a taller portrait aspect, o/w assume 720p
    let imageWidth;
    if (isImage) {
      imageWidth = Math.floor(Math.max(imageAspect * imageHeight, imageHeight * 0.85));
    } else if (isAvatar) {
      imageWidth = Math.floor((9 / 16) * imageHeight);
    } else {
      imageWidth = Math.floor(Math.max((16 / 9) * imageHeight, imageHeight * 0.85));
    }

    return [imageWidth, imageHeight];
  }, [isImage, isAvatar, imageAspect]);
}

function useThumbnail(entry, processThumbnailUrl) {
  const isImage = entry.type.endsWith("_image");
  const isAvatar = entry.type === "avatar" || entry.type === "avatar_listing";
  const imageAspect = entry.images.preview.width / entry.images.preview.height;

  const [thumbnailWidth, thumbnailHeight] = useThumbnailSize(isImage, isAvatar, imageAspect);

  const thumbnailUrl = useMemo(() => {
    return processThumbnailUrl ? processThumbnailUrl(entry, thumbnailWidth, thumbnailHeight) : entry.images.preview.url;
  }, [entry, thumbnailWidth, thumbnailHeight, processThumbnailUrl]);

  return [thumbnailUrl, thumbnailWidth, thumbnailHeight];
}

function TileActions({ actions }) {
  const intl = useIntl();

  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        <Button appearance="transparent" icon={<MoreVertical20Filled />} />
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {actions.map(action => (
            <MenuItem key={action.id} icon={action.icon} onClick={action.onClick}>
              {intl.formatMessage(action.label)}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}

TileActions.propTypes = {
  actions: PropTypes.array
};

function BaseTile({
  as: TileComponent,
  className,
  name,
  description,
  tall,
  wide,
  children,
  createTile,
  actions,
  ...rest
}) {
  let additionalProps;

  if (TileComponent === "div") {
    additionalProps = {
      tabIndex: "0",
      role: "button"
    };
  }

  return (
    <Card
      style={{
        backgroundColor: "var(--colorNeutralBackground3)"
      }}
      className={className}
      {...additionalProps}
      {...rest}
    >
      {createTile && (
        <div className="create-tile-borders">
          <Border></Border>
        </div>
      )}
      <CardPreview
        style={{
          backgroundColor: "var(--primaryColorBackground)"
        }}
      >
        {children}
      </CardPreview>
      {name || description || actions ? (
        <CardFooter className={styles.tileFooter} action={actions && <TileActions actions={actions} />}>
          {(name || description) && (
            <div>
              {name}
              {description && description.length > 0 && <span className={styles.description}>{description}</span>}
            </div>
          )}
        </CardFooter>
      ) : null}
    </Card>
  );
}

BaseTile.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.node,
  children: PropTypes.node,
  tall: PropTypes.bool,
  wide: PropTypes.bool
};

BaseTile.defaultProps = {
  as: "div"
};

function TileAction({ className, children, ...rest }) {
  return (
    <Button appearance="transparent" className={classNames(styles.tileAction, className)} {...rest}>
      {children}
    </Button>
  );
}

TileAction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export function CreateTile({ label, type, ...rest }) {
  return (
    <BaseTile
      createTile={true}
      className={styles.createTile}
      wide={type === "scene"}
      tall={type === "avatar"}
      {...rest}
    >
      <div className={styles.createTileContent}>
        <AddIcon width={48} height={48} />
        <p>{label}</p>
      </div>
    </BaseTile>
  );
}

CreateTile.propTypes = {
  label: PropTypes.node,
  type: PropTypes.string
};

export function LoadingTile({ progress }) {
  return (
    <div className={styles.loadingTile}>
      <Spinner
        size="huge"
        appearance="primary"
        label={<FormattedMessage id="media-browser.loading-avatar" defaultMessage="Loading avatar..." />}
        labelPosition="below"
      />
      {progress && (
        <div className={styles.progress}>
          <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
        </div>
      )}
    </div>
  );
}

LoadingTile.propTypes = {
  progress: PropTypes.number
};

export function MediaTile({
  entry,
  processThumbnailUrl,
  onClick,
  onEdit,
  onShowSimilar,
  onCopy,
  onInfo,
  onDelete,
  avatarSelected,
  ...rest
}) {
  const intl = useIntl();
  const creator = entry.attributions && entry.attributions.creator;
  const publisherName =
    (entry.attributions && entry.attributions.publisher && entry.attributions.publisher.name) ||
    PUBLISHER_FOR_ENTRY_TYPE[entry.type];

  const [thumbnailUrl, thumbnailWidth, thumbnailHeight] = useThumbnail(entry, processThumbnailUrl);

  const deleteAvatar = async e => {
    e.preventDefault();
    onDelete(entry);
  };

  const downloadAvatar = async e => {
    e.preventDefault();

    const link = document.createElement("a");
    link.href = entry.gltfs.avatar;
    link.download = `${entry.name || "avatar"}.gltf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const avatarActions = [
    {
      id: "edit",
      label: { id: "avatar-editor.rename-avatar-button", defaultMessage: "Rename" },
      icon: <Rename20Filled />,
      onClick: onEdit
    },
    {
      id: "download",
      label: { id: "invite-popover.download", defaultMessage: "Download" },
      icon: <ArrowDownload20Filled />,
      onClick: downloadAvatar
    },
    {
      id: "delete",
      label: { id: "object-menu.delete-object-button", defaultMessage: "Delete" },
      icon: <Delete20Filled />,
      onClick: deleteAvatar
    }
  ];

  const avatarListingActions = [
    {
      id: "copy",
      label: { id: "object-menu.copy-object-button", defaultMessage: "Copy" },
      icon: <Copy20Filled />,
      onClick: onCopy
    }
  ];

  const action =
    entry.type === "avatar_listing" && entry.allow_remixing
      ? avatarListingActions
      : entry.type === "avatar"
      ? avatarActions
      : undefined;

  return (
    <BaseTile
      actions={action}
      wide={entry.type === "scene" || entry.type === "scene_listing" || entry.type === "room"}
      tall={entry.type === "avatar" || entry.type === "avatar_listing"}
      name={entry.name}
      className={entry.type == "avatar" && avatarSelected && entry.id === avatarSelected.id ? "active" : ""}
      description={
        <>
          {entry.type === "room" && entry.description && <>{entry.description}</>}
          {creator && creator.name && entry.type == "collection" && (
            <div className={styles.collectionTile}>
              <span>{creator.name}</span>
              {entry.collection && (
                <div>
                  <span>{entry.collection.name}</span> <ExternalLinkIcon />
                </div>
              )}
            </div>
          )}
          {creator && creator.name === undefined && <span>{creator}</span>}
          {creator && creator.name && !creator.url && entry.type != "collection" && <span>{creator.name}</span>}
          {creator && creator.name && creator.url && (
            <a href={creator.url} target="_blank" rel="noopener noreferrer">
              {creator.name}
            </a>
          )}
          {publisherName && (
            <>
              <br />
              <a href={entry.url} target="_blank" rel="noopener noreferrer">
                <ExternalLinkIcon /> {publisherName}
              </a>
            </>
          )}
          {entry.last_activated_at && (
            <>
              <br />
              <FormattedMessage
                id="media-tile.joined-room"
                defaultMessage="Joined {relativeTime}"
                className={styles.joinedAt}
                values={{
                  relativeTime: (
                    <FormattedRelativeTime
                      updateIntervalInSeconds={10}
                      value={(new Date(entry.last_activated_at).getTime() - Date.now()) / 1000}
                    />
                  )
                }}
              />
            </>
          )}
        </>
      }
      {...rest}
    >
      <a className={styles.thumbnailLink} href={entry.url} rel="noreferrer noopener" onClick={onClick}>
        {entry.images.preview.type === "mp4" ? (
          <video
            muted
            autoPlay
            playsInline
            loop
            src={thumbnailUrl}
            alt={entry.name}
            width={thumbnailWidth}
            height={thumbnailHeight}
          />
        ) : (
          <img src={thumbnailUrl} alt={entry.name} width={thumbnailWidth} height={thumbnailHeight} />
        )}
      </a>
      {entry.favorited && <StarIcon className={styles.favoriteIcon} />}
      {entry.member_count !== undefined && (
        <div className={styles.memberCount}>
          <PeopleIcon /> <span>{entry.member_count}</span>
        </div>
      )}
      <div className={entry.type === "avatar" || entry.type === "avatar_listing" ? undefined : styles.tileActions}>
        {entry.type === "avatar" && (
          <>
            {/* <TileAction title={entry.type} onClick={deleteAvatar}>
              <TrashIcon />
            </TileAction>
            <TileAction title={entry.type} onClick={onEdit}>
              <PenIcon />
            </TileAction> */}
          </>
        )}
        {entry.type === "scene" && entry.project_id && (
          <TileAction
            onClick={onEdit}
            title={intl.formatMessage({ id: "media-tile.action.edit-scene", defaultMessage: "Edit scene" })}
          >
            <PenIcon />
          </TileAction>
        )}
        {/* TODO: Evaluate this feature and enable it if it's ready */}
        {/* {entry.type === "avatar_listing" && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.show-similar-avatars",
              defaultMessage: "Show similar avatars"
            })}
            onClick={onShowSimilar}
          >
            <Search20Filled />
          </TileAction>
        )} */}
        {/* {entry.type === "avatar_listing" && entry.allow_remixing && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.copy-avatar",
              defaultMessage: "Copy to my avatars"
            })}
            onClick={onCopy}
          >
            <DuplicateIcon />
          </TileAction>
        )} */}
        {entry.type === "scene_listing" && entry.allow_remixing && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.copy-scene",
              defaultMessage: "Copy to my scenes"
            })}
            onClick={onCopy}
          >
            <DuplicateIcon />
          </TileAction>
        )}
        {entry.type === "room" && onInfo && entry.description && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.room-info",
              defaultMessage: "Room info"
            })}
            onClick={onInfo}
          >
            <HelpIcon />
          </TileAction>
        )}
      </div>
    </BaseTile>
  );
}

MediaTile.propTypes = {
  entry: PropTypes.object.isRequired,
  processThumbnailUrl: PropTypes.func,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onShowSimilar: PropTypes.func,
  onCopy: PropTypes.func,
  onInfo: PropTypes.func,
  onDelete: PropTypes.func
};
