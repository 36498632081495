import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "../input/Button";
import styles from "./HaveARoomCodeButton.scss";

export function HaveARoomCodeButton({ mobile = false }) {
  return (
    <Button as="a" href="/link" className={mobile ? styles.mobileHaveARoomCodeButton : styles.haveARoomCodeButton}>
      <FormattedMessage id="home-page.have-code" defaultMessage="Have a metaverse code?" />
    </Button>
  );
}

HaveARoomCodeButton.propTypes = {
  mobile: PropTypes.bool
};
